import React from 'react';
import { connect } from "react-redux"
import styled from 'styled-components';
import media from 'styled-media-query';

import Hero from '@components/sitetop/hero';
import SectionTitle from '@components/sitetop/section_title';
import AboutUs from '@components/sitetop/about_us';
import WhatWeDo from '@components/sitetop/what_we_do/';
import Column from '@components/sitetop/column';
import Our_customer from '@components/sitetop/our_customer';
import NewsList from '@components/common/news-list';

import Btn from '@components/common/btn';

import Ttl__aboutus from '@img/svg/sitetop/ttl__aboutus.svg';
import Whatweare from '@img/svg/sitetop/ttl__whatweare.svg';
import Ttl__ourstrength from '@img/svg/sitetop/ttl__ourstrength.svg';
import Ttl__ourservice from '@img/svg/sitetop/ttl__ourservices.svg';
import Whatwedo from '@img/svg/sitetop/ttl__whatwedo.svg';
import Ttl__whatisnews from '@img/svg/sitetop/ttl__whatisnews.svg';
import { HeroTitle, OurCustomers } from '@components/sitetop/svgs';

import Layout from '@components/common/layout';

import { size, mixin, breakpoint } from '@src/settings';

const IndexPage: React.FC = ({ winSize, data }) => {

	const posts = [];
	data.posts.edges.map(post => {
		posts.push({
			date: post.node.date,
			title: post.node.title,
			to: `/news/${ post.node.wordpress_id }`
		});
	});

	return (
		<PageCont>
				<Hero
					className="hero"
					title={<HeroTitle style={{ fill: "#FFF", }} />}
					desc={<>マレーシア随一の”一貫生産体制”。<br/>徹底した品質管理と柔軟な対応力で<br className="pc"/>確かなサービスを提供します。</>}
					image={ data['img_hero'].childImageSharp.fluid }
				/>
				<SectionTitle
					className="sectionTitle title_whoWeAre"
					title={<Whatweare />}
					subTitle={<>LFSテクノロジーについて</>}
					align="center"
				/>

				<AboutUs 
					className="aboutUs"
					title={<Ttl__aboutus />}
					subTitle={<>一貫生産体制によって<br/>ニーズを満たし、感動を生む</>}
					desc={<>1980年代から金型に携わり、2000年には金型事業とプレス事業を合併した企業に生まれ変わって、マレーシアで唯一の一貫生産体制を実現しました。これからも、テクノロジー革新と優秀な人材育成を継続し、国内外の大手自動車メーカー様から信頼いただける製品・サービスをお届けしてまいります。</>}
					img={  data[ winSize[0] > breakpoint.tb ? 'img_aboutus' : 'img_aboutus_sp' ].childImageSharp.fluid }
				/>

				<WhatWeDo
					className="whatWeDo"
					title={<Whatwedo />}
					subTitle={<>事業のご紹介</>}
					infoList={
						[
							{
								ja: "製作フロー",
								en: "PRODUCTION FLOW",
								cap: "プロトタイプの作成・改良を含む、車体の金型の設計・製造といった金型事業。それらのプレス・溶接・組み立てを経たボディーパーツの組立事業まで、すべて同じ工場内で行っております。当社は、課題改善、品質向上がスピーティに行える、一貫生産体制を整えているマレーシア唯一の企業です。",
								to: "/flow",
							},
							{
								ja: "製品紹介",
								en: "OUR PRODUCTS",
								cap: "外板部品、シェルボディ部品、アンダー部品、アッセンブリ部品、精密機械加工部品まで、サイズも形状も様々な自動車の車体製造を行っています。ご要望に合わせた、オーダメイドの設計・製造も承ります。",
								to: "/products"
							},
							{
								ja: "品質・環境への取り組み",
								en: "QUALITY",
								cap: "当社\nが着手する事業の指針となっているのは、グローバルな視点で知られるトヨタ生産方式（TPS）です。お客様のニーズをしっかり汲み取り、必要なものだけ無駄なく生産する。それが製品の品質向上だけでなく、働きやすい環境づくり、さらに地球環境との共生に向けた取り組みにつながっていくと考えています。",
								to: "/quality"
							}
						]
					}
				/>

				<Column
					className="columns"
					img_pc={ data['strengthIntro'].childImageSharp.fluid }
					img_sp={ data['strengthIntro_sp'].childImageSharp.fluid }
					title={<Ttl__ourstrength />}
					subTitle="私たちの強み"
					desc={<>世界に視野を広げてトヨタ生産方式を導入し、2000年からは一貫生産体制を実施。お客様のニーズに合ったクオリティと納期、そしてコスト競争力を叶えるパートナー企業として成長してまいりました。より良い品質を追求する熱意ある社員とともに、車体部品製造分野のパイオニアとして、マレーシアの自動車業界に貢献してまいります。</>}
					to="/strength" 
				/>

				<Column
					className="columns"
					img_pc={ data['serviceIntro'].childImageSharp.fluid }
					img_sp={ data['serviceIntro_sp'].childImageSharp.fluid }
					title={<Ttl__ourservice />}
					subTitle="事業紹介"
					desc={<>大手メーカーの一次サプライヤーとして技術革新、今後も車体生産業の先頭を走ってまいります。</>}
					to="/services" 
					textBg="black"
					reversed={ true }
					isBottom={ true }
				/>

				<Our_customer
					className="ourCustomer"
					title={<OurCustomers  />}
					subTitle="主要顧客"
					data={data}
					logoList={[
						'Custumer_perodua',
						'Custumer_toyota',
						'Custumer_toyota_ab',
						'Custumer_proton',
						'Custumer_akashikikai',
						'Custumer_miyazu',
						'Custumer_isuzu',
						'Custumer_epmb',
					]}
				/>
				<section className="news">
					<SectionTitle
						className="sectionTitle"
						title={<Ttl__whatisnews />}
						subTitle={"新着のお知らせ"}
					/>
					<NewsList
						max={5}
						posts={ posts }
					/>
				</section>
		</PageCont>
	)
}

const PageCont = styled(Layout)`
	.hero{
		margin-bottom: 10rem;
	}
	.sectionTitle{
		margin-bottom: 5rem;
	}
	.aboutUs{
		${ mixin.baseWidthSet }
		margin-bottom: 5.8rem;
	}
	.whatWeDo{
		${ mixin.baseWidthSet }
		margin-bottom: 10rem;
	}
	.columns{
		${ mixin.baseWidthSet }
	}
	.ourCustomer{
		${ mixin.baseWidthSet }
		margin: 11rem auto;
	}
	.news{
    margin-top: 4rem;
    padding: 11rem 0 13rem;
    background: #EBEFF4;
	}
	${ media.lessThan(`${ breakpoint.tb }px`)`
	.title_whoWeAre{
		color: #FFF;
		& > .title > svg{
			fill: #FFF;
		}
	}
	.columns + .columns{
		margin-top: 8rem;
	}
	.news{
		padding: 8rem 0;
	}
	.aboutUs{
		margin-bottom: 7rem;
	}
	.whatWeDo{
		width: 100%;
		max-width: inherit;
	}
	`}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		.hero{
			margin-bottom: 7rem;
		}
		.columns + .columns{
			margin-top: 6rem;
		}
		.ourCustomer{
			margin: 7rem auto 6rem;
		}
	`}
`

export const query = graphql`
	query sitetop {
		img_hero:file(relativePath: {eq: "sitetop/hero-img_200629.jpg"}) {
			childImageSharp {
				fluid(maxWidth: 1350, quality: 90) {
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		img_aboutus:file(relativePath: {eq: "sitetop/aboutintro.jpg"}) {
			childImageSharp {
				fluid(maxWidth: 800, quality: 90) {
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		img_aboutus_sp:file(relativePath: {eq: "sitetop/aboutintro--sp.jpg"}) {
			childImageSharp {
				fluid(maxWidth: 820, quality: 90) {
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		serviceIntro: file( relativePath: { eq: "sitetop/serviceintro.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		serviceIntro_sp: file( relativePath: { eq: "sitetop/serviceintro--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		}
		strengthIntro: file( relativePath: { eq: "sitetop/strengthintro.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		strengthIntro_sp: file( relativePath: { eq: "sitetop/strengthintro--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		Custumer_perodua: file( relativePath: { eq: "sitetop/custumer-perodua.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Custumer_isuzu: file( relativePath: { eq: "sitetop/custumer-isuzu.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Custumer_proton: file( relativePath: { eq: "sitetop/custumer-proton.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Custumer_epmb: file( relativePath: { eq: "sitetop/custumer-epmb.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Custumer_miyazu: file( relativePath: { eq: "sitetop/custumer-miyazu.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Custumer_toyota: file( relativePath: { eq: "sitetop/custumer-toyota.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Custumer_toyota_ab: file( relativePath: { eq: "sitetop/custumer-toyota-ab.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		Custumer_akashikikai: file( relativePath: { eq: "sitetop/custumer-akashikikai.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
		posts: allWordpressPost(filter: {categories: {eq: 4}}, sort: {fields: date}) {
    edges {
      node {
        date(formatString: "Y.M.D")
        title
        wordpress_id
      }
    },
  }
	}
`

const mapStateToProps = state => ({
	winSize: state.winsizeReducer.winSize,
})
export default connect(mapStateToProps)(IndexPage);
